<!-- 大乐透一区走势图 -->
<template>
  <div class="">
    <div class="ssq-table">
      <div class="ssq-table-header">
        <div class="ssq-icon">
          <svg t="1629609108732" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="2399" width="45" height="45">
            <path d="M452.846933 52.986311h117.771378l-127.724089 392.436622h-117.543822z" fill="#322C2B" p-id="2400">
            </path>
            <path d="M29.641956 446.839467l36.189866-111.889067 333.969067 242.591289-36.420267 111.741155z"
              fill="#0C7CBE" p-id="2401"></path>
            <path d="M273.965511 971.013689l-95.274667-69.290667 333.7216-242.212978 95.379912 69.048889z"
              fill="#EFB918" p-id="2402"></path>
            <path d="M847.798044 900.616533l-95.277511 69.1456-127.374222-392.220444 95.152356-69.304889z"
              fill="#14934A" p-id="2403"></path>
            <path d="M958.196622 333.348978l36.161422 111.746844H581.808356l-36.411734-111.746844z" fill="#D7282A"
              p-id="2404"></path>
          </svg>
          <div class="ssq-one">
            <h2>中国体育彩票</h2>
          </div>
          <div class="ssq-two">
            <h3>大 乐 透 二 区 尾 数 走 势 图</h3>
          </div>
          <div class="clear"></div>
        </div>
      </div>
      <table class="tab" border="1" cellspacing="0" cellpadding="0">
        <thead class="thead-fixed">
          <tr>
            <th rowspan="2" class="ranks_a">期号</th>
            <!-- <th rowspan="2" class="ranks_a1">星期</th> -->
            <th colspan="10" class="ranks_b">二 区 尾 数 走 势 图</th>
            <th colspan="3" class="ranks_b">二区012路</th>
            <th colspan="3" class="ranks_b">二区147路</th>
            <th colspan="3" class="ranks_b">二区258路</th>
            <th colspan="2" class="ranks_b">奇 偶</th>
            <th colspan="2" class="ranks_b">大 小</th>
            <th colspan="2" class="ranks_b">质 合</th>
            <th colspan="6" class="ranks_b">二区出号个数</th>
            <th colspan="3" class="ranks_b">个数012路</th>
            <th colspan="3" class="ranks_b">个数147路</th>
            <th colspan="3" class="ranks_b">个数258路</th>
            <th colspan="2" class="ranks_b">奇 偶</th>
            <th colspan="2" class="ranks_b">大 小</th>
            <th colspan="2" class="ranks_b">质 合</th>
            <th colspan="5" class="ranks_b">二区五行走势图</th>
          </tr>
          <tr>
            <th class="ranks_c">0</th>
            <th class="ranks_c">1</th>
            <th class="ranks_c">2</th>
            <th class="ranks_c">3</th>
            <th class="ranks_c">4</th>
            <th class="ranks_c">5</th>
            <th class="ranks_c">6</th>
            <th class="ranks_c">7</th>
            <th class="ranks_c">8</th>
            <th class="ranks_c">9</th>
            <th class="ranks_c">0</th>
            <th class="ranks_c">1</th>
            <th class="ranks_c">2</th>
            <th class="ranks_c">1</th>
            <th class="ranks_c">4</th>
            <th class="ranks_c">7</th>
            <th class="ranks_c">2</th>
            <th class="ranks_c">5</th>
            <th class="ranks_c">8</th>
            <th class="ranks_c">奇</th>
            <th class="ranks_c">偶</th>
            <th class="ranks_c">大</th>
            <th class="ranks_c">小</th>
            <th class="ranks_c">质</th>
            <th class="ranks_c">合</th>
            <th class="ranks_c">0</th>
            <th class="ranks_c">1</th>
            <th class="ranks_c">2</th>
            <th class="ranks_c">3</th>
            <th class="ranks_c">4</th>
            <th class="ranks_c">5</th>

            <th class="ranks_c">0</th>
            <th class="ranks_c">1</th>
            <th class="ranks_c">2</th>
            <th class="ranks_c">1</th>
            <th class="ranks_c">4</th>
            <th class="ranks_c">7</th>
            <th class="ranks_c">2</th>
            <th class="ranks_c">5</th>
            <th class="ranks_c">8</th>
            <th class="ranks_c">奇</th>
            <th class="ranks_c">偶</th>
            <th class="ranks_c">大</th>
            <th class="ranks_c">小</th>
            <th class="ranks_c">质</th>
            <th class="ranks_c">合</th>
            <th class="ranks_c">金</th>
            <th class="ranks_c">木</th>
            <th class="ranks_c">水</th>
            <th class="ranks_c">火</th>
            <th class="ranks_c">土</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item2, x) in dlt" :key="x">
            <td>{{ item2.expect }}</td>
            <!-- <td>{{}}</td> -->
            <!-- 一区尾数 -->
            <td class="b-red" v-for="(item, i) in red" :key="i">
              <div :class="item == item1 ? 'q-red' : 'no'" v-for="(item1, j) in red0[x]" :key="j">
                {{ item == item1 ? item : "" }}
              </div>
            </td>
            <!-- 012路 -->
            <td class="b-lan" v-for="(item, i) in lye" :key="i">
              <div :class="item1 == item ? 'q-lan' : 'no'" v-for="(item1, j) in lye1[x]" :key="j">
                {{ item1 == item ? item : "" }}
              </div>
            </td>
            <!-- 147路 -->
            <td class="b-red" v-for="(item, i) in ysq" :key="i">
              <div :class="item1 == item ? 'q-red' : 'no'" v-for="(item1, j) in ysq1[x]" :key="j">
                {{ item1 == item ? item : "" }}
              </div>
            </td>
            <!-- 258路 -->
            <td class="b-lan" v-for="(item, i) in ewb" :key="i">
              <div :class="item1 == item ? 'q-lan' : 'no'" v-for="(item1, j) in ewb1[x]" :key="j">
                {{ item1 == item ? item : "" }}
              </div>
            </td>
            <!-- 一区奇偶 -->
            <td class="b-red" v-for="(item, i) in jo" :key="i">
              <div :class="item1 == item ? 'q-red' : 'no'" v-for="(item1, j) in jo1[x]" :key="j">
                {{ item1 == item ? item : "" }}
              </div>
            </td>
            <!-- 一区大小 -->
            <td class="b-lan" v-for="(item, i) in dx" :key="i">
              <div :class="item1 == item ? 'q-lan' : 'no'" v-for="(item1, j) in dx1[x]" :key="j">
                {{ item1 == item ? item : "" }}
              </div>
            </td>
            <!-- 一区质合 -->
            <td class="b-red" v-for="(item, i) in zh" :key="i">
              <div :class="item1 == item ? 'q-red' : 'no'" v-for="(item1, j) in zh1[x]" :key="j">
                {{ item1 == item ? item : "" }}
              </div>
            </td>
            <!-- 一区个数 -->
            <td class="b-lan" v-for="(item, i) in zf" :key="i">
              <div :class="gs1[x] == item ? 'q-lan' : 'no'">
                {{ gs1[x] == item ? item : "" }}
              </div>
            </td>
            <!-- 个数012路 -->
            <td class="b-red" v-for="(item, i) in lye" :key="i">
              <div :class="gs1[x]%3 == item ? 'q-red' : ''">
                {{ gs1[x]%3 == item ? item : "" }}
              </div>
            </td>
            <!-- 个数147路 -->
            <td class="b-lan" v-for="(item, i) in ysq" :key="i">
              <div
                :class="(([1,5,6,9].indexOf(gs1[x])==-1 ? '' : 1)||([0,2,4].indexOf(gs1[x])==-1 ? '' : 4)||([3,7,8].indexOf(gs1[x])==-1 ? '' : 7)) == item ? 'q-lan' : '' ">
                {{ (([1,5,6,9].indexOf(gs1[x])==-1 ? '' : 1)||([0,2,4].indexOf(gs1[x])==-1 ? '' :
                4)||([3,7,8].indexOf(gs1[x])==-1 ? '' : 7)) == item ? item : "" }}
              </div>
            </td>
            <!-- 个数258路 -->
            <td class="b-red" v-for="(item, i) in ewb" :key="i">
              <div
                :class="(([2,7,9].indexOf(gs1[x])==-1 ? '' : 2)||([0,3,5].indexOf(gs1[x])==-1 ? '' : 5)||([1,4,6,8].indexOf(gs1[x])==-1 ? '' : 8)) == item ? 'q-red' : ''  ">
                {{ (([2,7,9].indexOf(gs1[x])==-1 ? '' : 2)||([0,3,5].indexOf(gs1[x])==-1 ? '' :
                5)||([1,4,6,8].indexOf(gs1[x])==-1 ? '' : 8)) == item ? item : '' }}
              </div>
            </td>
            <!-- 个数奇偶 -->
            <td class="b-lan" v-for="(item, i) in jo" :key="i">
              <div :class=" (gs1[x]%2==0 ? '偶':'奇') ==item ? 'q-lan' : '' ">
                {{ (gs1[x]%2==0 ? '偶':'奇') ==item ? item : ''}}
              </div>
            </td>
            <!-- 个数大小 -->
            <td class="b-red" v-for="(item, i) in dx" :key="i">
              <div :class=" (gs1[x]>4 ? '大':'小') ==item ? 'q-red' : '' ">
                {{ (gs1[x]>4 ? '大':'小') ==item ? item : ''}}
              </div>
            </td>
            <!-- 个数质合 -->
            <td class="b-lan" v-for="(item, i) in zh" :key="i">
              <div :class="([1,2,3,5,7].indexOf(gs1[x])==-1 ? '合' : '质') == item ? 'q-lan' : ''  ">
                {{ ([1,2,3,5,7].indexOf(gs1[x])==-1 ? '合' : '质') == item ? item : '' }}
              </div>
            </td>
            <!-- 一区五行走势图 -->
            <td class="b-red" v-for="(item, i) in wx" :key="i">
              <div :class="  item ==(1==gs1[x]||9==gs1[x]? '金':''||2==gs1[x]||8==gs1[x]? '木':''||
                3==gs1[x]||7==gs1[x]? '水':''||
                4==gs1[x]||6==gs1[x]?'火':''||0==gs1[x]||5==gs1[x]?'土':'')? 'q-red' : '' ">
                {{ item ==(1==gs1[x]||9==gs1[x]? '金':''||2==gs1[x]||8==gs1[x]? '木':''||
                3==gs1[x]||7==gs1[x]? '水':''||
                4==gs1[x]||6==gs1[x]?'火':''||0==gs1[x]||5==gs1[x]?'土':'')? item : '' }}
              </div>
            </td>
          </tr>
          <tr>
            <td>待开奖</td>
            <td v-for="(x, i) in 51" :key="i">
              <div :class="x == i + 1 ? 'xuan' : ''">
                {{ "?" }}
              </div>
            </td>
          </tr>
          <tr>
            <td>待开奖</td>
            <td v-for="(x, i) in 51" :key="i">
              <div :class="x == i + 1 ? 'xuan' : ''">
                {{ "?" }}
              </div>
            </td>
          </tr>
          <tr>
            <td>待开奖</td>
            <td v-for="(x, i) in 51" :key="i">
              <div :class="x == i + 1 ? 'xuan' : ''">
                {{ "?" }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import mitt from '@/js/mitt.js'
  //import '../js/font.js' //js文件引入，注意路径
  //import {myfun} from '../js/test.js' //js文件引入，注意路径
  //import Introduction from '@/views/Introduction.vue'

  export default {
    name: "red-tail",
    components: {},
    data() {
      // 数据源
      return {

        red: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
        red0: [],
        lye: [0, 1, 2],
        lye1: [],
        ysq: [1, 4, 7],
        ysq1: [],
        ewb: [2, 5, 8],
        ewb1: [],
        jo: ["奇", "偶"],
        jo1: [],
        dx: ["大", "小"],
        dx1: [],
        zh: ["质", "合"],
        zh1: [],
        zf: [0, 1, 2, 3, 4, 5],
        gs1: [],
        wx: ["金", "木", "水", "火", "土"],
        wx1: [],

      };
    },

    mounted() {
      this.mred();
      this.mlye();
      this.mysq();
      this.mewb();
      this.mjo();
      this.mdx();
      this.mzh();
      this.mgs();
      this.find()
    },
    computed: {
      dlt() {
        return this.$store.state.dlt;
      },
    },

    methods: {
      // 函数体部分，js的主要逻辑控制
      find() {
        document.documentElement.scrollTop = 0;
        if (this.dlt == '') {
          mitt.emit('dlt');
        }
      },

      mred() { //出号尾数
        this.red0 = []
        for (var i = 0; i < this.dlt.length; i++) {
          var xx = new Array();
          for (var j = 0; j < this.dlt[i].red.length; j++) {
            if (this.dlt[i].red[j] > 12 && this.dlt[i].red[j] <= 24) {
              if (xx.indexOf(this.dlt[i].red[j] % 10) == -1) {
                xx.push(this.dlt[i].red[j] % 10);

              }
            }
          }
          this.red0.push(xx);
          //console.log(this.red0);
        }
        // console.log(this.red0)
        //return xx
      },

      mlye() { //012路
        this.lye1 = []
        for (var i = 0; i < this.red0.length; i++) {
          var xx = new Array();
          for (var j = 0; j < this.red0[i].length; j++) {
            if (xx.indexOf(this.red0[i][j] % 3) == -1) {
              xx.push(this.red0[i][j] % 3);
            }
          }
          this.lye1.push(xx);
        }
      },

      mysq() {  //147路
        this.ysq1 = []
        for (var i = 0; i < this.red0.length; i++) {
          var xx = new Array();
          for (var j = 0; j < this.red0[i].length; j++) {
            if (
              this.red0[i][j] == 1 ||
              this.red0[i][j] == 5 ||
              this.red0[i][j] == 6 ||
              this.red0[i][j] == 9
            ) {
              if (xx.indexOf(1) == -1) {
                xx.push(1);
              }
            } else if (
              this.red0[i][j] == 0 ||
              this.red0[i][j] == 2 ||
              this.red0[i][j] == 4
            ) {
              if (xx.indexOf(4) == -1) {
                xx.push(4);
              }
            } else if (
              this.red0[i][j] == 3 ||
              this.red0[i][j] == 7 ||
              this.red0[i][j] == 8
            ) {
              if (xx.indexOf(7) == -1) {
                xx.push(7);
              }
            }
          }
          this.ysq1.push(xx);
        }
      },

      mewb() {  //258路
        this.ewb1 = []
        for (var i = 0; i < this.red0.length; i++) {
          var xx = new Array();
          for (var j = 0; j < this.red0[i].length; j++) {
            if (
              this.red0[i][j] == 2 ||
              this.red0[i][j] == 7 ||
              this.red0[i][j] == 9
            ) {
              if (xx.indexOf(2) == -1) {
                xx.push(2);
              }
            } else if (
              this.red0[i][j] == 0 ||
              this.red0[i][j] == 3 ||
              this.red0[i][j] == 5
            ) {
              if (xx.indexOf(5) == -1) {
                xx.push(5);
              }
            } else if (
              this.red0[i][j] == 1 ||
              this.red0[i][j] == 4 ||
              this.red0[i][j] == 6 ||
              this.red0[i][j] == 8
            ) {
              if (xx.indexOf(8) == -1) {
                xx.push(8);
              }
            }
          }
          this.ewb1.push(xx);
        }
      },

      mjo() {  //奇偶
        this.jo1 = []
        for (var i = 0; i < this.red0.length; i++) {
          var xx = new Array();
          for (var j = 0; j < this.red0[i].length; j++) {
            if (this.red0[i][j] % 2 == 0) {
              if (xx.indexOf("偶") == -1) {
                xx.push("偶");
              }
            } else {
              if (xx.indexOf("奇") == -1) {
                xx.push("奇");
              }
            }
          }
          this.jo1.push(xx);
        }
      },

      mdx() {  //大小
        this.dx1 = []
        for (var i = 0; i < this.red0.length; i++) {
          var xx = new Array();
          for (var j = 0; j < this.red0[i].length; j++) {
            if (this.red0[i][j] < 5) {
              if (xx.indexOf("小") == -1) {
                xx.push("小");
              }
            } else {
              if (xx.indexOf("大") == -1) {
                xx.push("大");
              }
            }
          }
          this.dx1.push(xx);
        }
      },

      mzh() {  //质合
        this.zh1 = []
        for (var i = 0; i < this.red0.length; i++) {
          var xx = new Array();
          for (var j = 0; j < this.red0[i].length; j++) {
            if ([1, 2, 3, 5, 7].indexOf(this.red0[i][j]) == -1) {
              if (xx.indexOf("合") == -1) {
                xx.push("合");
              }
            } else {
              if (xx.indexOf("质") == -1) {
                xx.push("质");
              }
            }
          }
          this.zh1.push(xx);
        }
      },

      mgs() {  //个数
        this.gs1 = []
        for (var i = 0; i < this.dlt.length; i++) {
          var xx = new Array();
          for (var j = 0; j < this.dlt[i].red.length; j++) {
            if (this.dlt[i].red[j] > 12 && this.dlt[i].red[j] <= 24) {
              xx += 1
            }
          }
          this.gs1.push(xx.length);
          // console.log(this.gs1);
        }
        // console.log(this.red0)
        //return xx
      },
    },
    watch: {
      dlt() {
        this.mred();
        this.mlye();
        this.mysq();
        this.mewb();
        this.mjo();
        this.mdx();
        this.mzh();
        this.mgs();
      }
    }
  };
</script>

<style scoped>
  /* @import './swiper.css'; */
  .ssq-table-header {
    width: 100%;
    height: 50px;
    background-color: rgb(224, 202, 5);
  }

  .tab {
    margin: 0 auto;
    border-collapse: collapse;
  }

  .thead-fixed th {
    border: 1px solid #808080;
  }

  .ranks_a1 {
    width: 25px;
  }

  .ranks_c {
    width: 23px;
    color: red;
  }

  .ranks_d {
    width: 22px;
    color: blue;
  }

  .ssq-icon {
    text-align: left;
  }

  .icon {
    float: left;
  }

  .ssq-one {
    float: left;
    height: 42px;
    line-height: 42px;
    color: #fff;
  }

  .ssq-two {
    float: left;
    width: 80%;
    text-align: center;
    line-height: 42px;
    font-size: 25px;
    color: rgb(252, 251, 249);
  }

  tbody .red {
    background-color: red;
    border-radius: 15px;
    color: #fff;
  }

  tbody .xuan {
    background-color: rgb(160, 156, 156);
    border-radius: 15px;
    color: rgb(253, 253, 253);
  }

  tbody .lan {
    background-color: blue;
    color: #fff;
    border-radius: 15px;
  }

  .b-red {
    height: 23px;
    background-color: rgb(221, 201, 171);

  }

  .b-lan {
    background-color: rgb(184, 200, 230);
  }

  .q-red {
    background-color: rgb(158, 29, 147);
    border-radius: 15px;
    color: #fff;
  }

  .q-lan {
    background-color: rgb(212, 164, 3);
    color: #fff;
    border-radius: 15px;
  }

  .no {
    display: none;
  }
</style>